export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sale-representatives/:pageId',
        component: () => import('@/views/page/company/crm/sr/SRList.vue'),
        name: 'sale-representatives',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/dealer-list/:pageId',
        component: () => import('@/views/page/company/crm/dealer/DealerList.vue'),
        name: 'dealer-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-calendar/:pageId',
        component: () => import('@/views/page/company/crm/visit-calendar/VisitCalendar.vue'),
        name: 'visit-calendar',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-plan-list/:pageId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitPlanList.vue'),
        name: 'visit-plan-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-status/:pageId/update/:planId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitStatusUpdate.vue'),
        name: 'visit-status-update',
        meta: {
            requiresAuth: true
        }
    }
]
